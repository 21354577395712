@use "sass:map";

/********************************************************************
* Define themes:
********************************************************************/

$themes: (
    "default" : (
        "main-bg-color": #ffffff,
        "main-text-color": #585858,
        "background-image-color": #a9dbff,
        "base-font-size" : 15px,
        "base-font-family" : ("Segoe UI", "Charcoal", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif),
        "base-border": 0,
        "base-border-radius" : 12px,
        "base-shadow": (inset 0px 1px 8px -4px rgb(#000, .25), 0px 3px 8px -3px rgba(#000, .68)),
        "base-margin": 5px,
        "icon-warning-color": rgb(255, 175, 26),
        "icon-tides-color": #4daef6,
        "icon-tidehigh-color": #4daef6,
        "icon-tidelow-color": #4daef6,
        "title-font-weight" : 600,
        "title-font-scale" : 1.4em,
        "location-font-weight" : 600,
        "location-font-scale" : 1.4em,
        "date-font-weight" : 400,
        "date-font-scale" : 1.2em,
        "content-header-scale": 1em,
        "content-header-weight": 600,
        "content-table-border": 1px solid #adadad,
        "content-outer-margin": 5px 15px 0em 15px, 
        "content-rows-spacing" : 0.65em,
        "content-rows-align" : center,
        "source-min-width" : 45%,
        "source-max-width" : 100%,
        "source-font-scale" : 0.95em,
        "source-font-weight" : 400,
        "source-padding"    : 5px 0px 5px 0px,
        "source-link-color" : #4daef6,
        "source-link-hover" : #4daef6,
        "source-icon-color" : rgba(255,255,255, 60%),
        "source-link-bg"    : none,
        "flat-base-border-radius": 4px,
        "flat-base-border": 1px solid #d1d1d1,
        "flat-content-rows-border" : 1px solid rgba(0,0,0, 0.15)
    ),
    "dark" : (
        "main-bg-color": #535353,
        "main-text-color": #dfdfdf,
        "background-image-color": #898888,
        "icon-tides-color": #4daef6,
        "icon-tidehigh-color": #4daef6,
        "icon-tidelow-color": #4daef6,
        "content-table-border": 1px solid #747474,
        "source-link-color" : #dfdfdf,
        "source-link-hover" : #dfdfdf,
        "flat-base-border": 1px solid #424242,
        "flat-content-rows-border" : 1px solid rgba(255,255,255, 0.15)
    ),
    "space" : (
        "main-bg-color": #2b303c, 
        "main-text-color": #bec5d5,
        "background-image-color": #616671,
        "content-table-border": 1px solid #74809c,
        "source-link-color" : #bec5d5,
        "source-link-hover" : #bec5d5,
        "flat-base-border": 1px solid #2c2f35,
        "flat-content-rows-border" : 1px solid rgba(149,162,195, 0.53)
    ),
    "grey" : (
        "main-bg-color": #f7f7f7, 
        "main-text-color": #343434,
        "background-image-color": #c6c6c6,
        "content-table-border": 1px solid #acacac,
        "source-link-color" : #343434,
        "source-link-hover" : #343434,
        "flat-base-border": 1px solid #939393,
        "flat-content-rows-border" : 1px solid rgba(0,0,0, 0.15)
    ) 
); 




/********************************************************************
* Theme mixin for genearting the theme style rules:
********************************************************************/

@mixin theme($name, $values) {
    /*! THEME #{$name}:  **************/
    .tide-style.theme-#{$name} {
        font-size: map.get($values, "base-font-size");
        font-family: map.get($values, "base-font-family");
        background-color: map.get($values, "main-bg-color");
        color: map.get($values, "main-text-color");
        border: map.get($values, "base-border");
        border-radius: map.get($values, "base-border-radius");
        box-shadow: map.get($values, "base-shadow");
        margin: map.get($values, "base-margin");
        .tide-icon {
            &.warn {
                fill: map.get($values, "icon-warning-color");
            }
            &.hightide {
                fill: map.get($values, "icon-tidehigh-color");
            }
            &.lowtide {
                fill: map.get($values, "icon-tidelow-color");
            }
            &.marker {
                fill: map.get($values, "main-text-color");
            }
            &.waves {
                fill: map.get($values, "icon-tides-color");
            }
            &.loading {
        
            }
        }
        a {
            color: map.get($values, "main-text-color");
        }
    }
    .theme-#{$name} .tide-card-content { 
        >ul {
            margin: map.get($values, "content-outer-margin");
            li {
                text-align: map.get($values, "content-rows-align");
                padding-top: map.get($values, "content-rows-spacing");
                padding-bottom: map.get($values, "content-rows-spacing");
            }
        }
        .tide-card-bg {
            svg {
                path {
                    fill: map.get($values, "background-image-color") !important;
                }
            }
        }
        ul.tide-card-table-header {
            font-size:  map.get($values, "content-header-scale");
            font-weight: map.get($values, "content-header-weight");
            border-bottom: map.get($values, "content-table-border");
        }
    }
    .theme-#{$name} .tide-card-header {
        .tide-card-title {
            font-weight:  map.get($values, "main-text-color");
            font-size:  map.get($values, "title-font-scale");
            .tide-icon {
                @if map.has-key($values, "title-font-scale") {
                    width: calc( map.get($values, "title-font-scale") - 0.1em);
                    height: calc( map.get($values, "title-font-scale") - 0.1em);
                }
            }
        }
        .tide-card-location {
            font-weight: map.get($values, "location-font-weight");
            font-size: map.get($values, "location-font-scale");
            .tide-icon {
                @if map.has-key($values, "main-text-color") {
                    fill: map.get($values, "main-text-color") !important;
                }
                @if map.has-key($values, "location-font-scale") {
                    width: calc( map.get($values, "location-font-scale") - 0.2em);
                    height: calc( map.get($values, "location-font-scale") - 0.2em);
                }
            } 
        }
        .tide-card-date {
            font-weight: map.get($values, "date-font-weight");
            font-size: map.get($values, "date-font-scale");
        }
    }
    .theme-#{$name} .tide-card-source { 
        // @if map.has-key($values, "background-image-color") {
        //     background-color: rgba(map.get($values, "background-image-color"), .7);
        // }
        border-bottom-left-radius: map.get($values, "base-border-radius");
        border-bottom-right-radius: map.get($values, "base-border-radius");
        a {
            color: map.get($values, "source-link-color");
            min-width: map.get($values, "source-min-width");
            max-width: map.get($values, "source-max-width");
            font-size: map.get($values, "source-font-scale");
            background-color: map.get($values, "source-link-bg");
            padding: map.get($values, "source-padding");
            @if map.has-key($values, "base-border-radius") {
                border-radius: map.get($values, "base-border-radius") map.get($values, "base-border-radius") 0px 0px;
            }
            .tide-icon {
                fill: map.get($values, "source-icon-color");
            }
            &:hover,
            &:hover .tide-icon {
                color: map.get($values, "source-link-hover");
                fill: map.get($values, "source-link-hover");
            }
        }
    }
    .theme-#{$name}.tide-style.theme-flat {
        border-radius: map.get($values, "flat-base-border-radius");
        border: map.get($values, "flat-base-border");
        .tide-card-content>ul li {
            border-bottom: map.get($values, "flat-content-rows-border");
        }
        .tide-card-content>ul li:nth-last-child(-n+3) {
            border-bottom: 0px
        }
    }
}


@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function get-from-theme($name, $key) {
    @return map-deep-get($themes, $name, $key);
}
