@use 'themes' as themes;
@use "sass:map";

/********************************************************************
 * Main container style:
 ********************************************************************/
.tide-style { 
    all:revert;
    font-size: themes.get-from-theme("default", "base-font-size");
    font-family: themes.get-from-theme("default", "base-font-family");
    width:100%;
    border: themes.get-from-theme("default", "base-border");
    border-radius: themes.get-from-theme("default", "base-border-radius");
    background-color: themes.get-from-theme("default", "main-bg-color");
    color: themes.get-from-theme("default", "main-text-color");
    box-shadow: themes.get-from-theme("default", "base-shadow");
    margin: themes.get-from-theme("default", "base-margin");
    box-sizing: border-box;
    line-height: normal;
    overflow: hidden;
    * {
        box-sizing: border-box;
    }
    display: flex;
    flex-direction: column;
    a {
        text-decoration: none;
        color: themes.get-from-theme("default", "main-text-color");
        display: inline-block;
        white-space: pre-wrap;
        text-align: center;
    }
    .tide-icon {
        vertical-align: bottom;
        margin-right: 6px;
        width: 1.1em;
        height: 1.1em;
        &.warn {
            vertical-align: middle;
            fill: themes.get-from-theme("default", "icon-warning-color");
        }
        &.hightide {
            vertical-align: middle;
            fill: themes.get-from-theme("default", "icon-tidehigh-color");
        }
        &.lowtide {
            vertical-align: middle;
            fill: themes.get-from-theme("default", "icon-tidelow-color");
        }
        &.marker {
            fill: themes.get-from-theme("default", "main-text-color");
        }
        &.waves {
            fill: themes.get-from-theme("default", "icon-tides-color");
        }
        &.loading {
    
        }
    }
    >div {
        width: 100%;
    }
}


/********************************************************************
 * Header style:
 ********************************************************************/
.tide-card-header {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 15px 10px 10px 10px;
    >span {
        display: inline-block;
        white-space: pre-wrap;
        text-align: center;
    }
    .tide-card-title {
        padding-bottom:10px;
        font-weight: themes.get-from-theme("default", "main-text-color");
        font-size: themes.get-from-theme("default", "title-font-scale");
        .tide-icon {
            width: calc(themes.get-from-theme("default", "title-font-scale") - 0.1em);
            height: calc(themes.get-from-theme("default", "title-font-scale") - 0.1em);
        }
    }
    .tide-card-location {
        padding-bottom:10px;
        font-weight: themes.get-from-theme("default", "location-font-weight");
        font-size: themes.get-from-theme("default", "location-font-scale");
        .tide-icon {
            fill:  themes.get-from-theme("default", "main-text-color");
            opacity:.85; 
            width: calc(themes.get-from-theme("default", "location-font-scale") - 0.1em);
            height: calc(themes.get-from-theme("default", "location-font-scale") - 0.1em);
            vertical-align: baseline;
        }
    }
    .tide-card-date {
        font-weight: themes.get-from-theme("default", "date-font-weight");
        font-size: themes.get-from-theme("default", "date-font-scale");
    }
}

.tide-style.location-ellipsis {
    .tide-card-header {
        >span {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            text-align: center;
            max-width: 100%;
        }
        >a {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            white-space: nowrap;
            text-align: center;
            max-width: 100%;
        }
    }
}
/********************************************************************
 * Content style:
 ********************************************************************/

.tide-card-content { 
    position: relative;
    flex-grow: 1;
    // overflow: hidden;
    >ul {
        list-style: none;
        margin: themes.get-from-theme("default", "content-outer-margin");
        padding:0;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: flex-end;
        li {
            flex: 1 1 33.33%;
            text-align: themes.get-from-theme("default", "content-rows-align");
            padding-top: themes.get-from-theme("default", "content-rows-spacing");
            padding-bottom: themes.get-from-theme("default", "content-rows-spacing");
        }
    }
    .tide-card-bg {
        position: absolute;
        width:100%;
        height:100%;
        overflow: hidden;
        top:2em;
        z-index: 0;
        svg {
            width: 100%;
            height: auto;
            min-height: 100%;
            min-width: 100%;
            margin: 0 !important;
            path {
                fill: themes.get-from-theme("default", "background-image-color") !important;
            }
        }
    }
    ul.tide-card-table-header {
        font-size:  themes.get-from-theme("default", "content-header-scale");
        font-weight: themes.get-from-theme("default", "content-header-weight");
        border-bottom: themes.get-from-theme("default", "content-table-border");
        position: relative;
        z-index: 1;
    }
    ul.tide-card-table-values {
        position: relative;
        z-index: 2;
    }
}

/********************************************************************
 * Footer style:
 ********************************************************************/

.tide-card-source { 
    // background-color: rgba(themes.get-from-theme("default", "background-image-color"), .7);
    text-align: center;
    border-bottom-left-radius: themes.get-from-theme("default", "base-border-radius");
    border-bottom-right-radius: themes.get-from-theme("default", "base-border-radius");
    position: relative;
    z-index: 3;
    a {
        color: themes.get-from-theme("default", "source-link-color");
        text-decoration: underline;
        min-width: themes.get-from-theme("default", "source-min-width");
        max-width: themes.get-from-theme("default", "source-max-width");
        display: inline-block;
        font-size: themes.get-from-theme("default", "source-font-scale");
        font-weight: themes.get-from-theme("default", "source-font-weight");
        padding: themes.get-from-theme("default", "source-padding");
        border-radius: themes.get-from-theme("default", "base-border-radius") themes.get-from-theme("default", "base-border-radius") 0px 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: bottom;
        .tide-icon {
            fill: themes.get-from-theme("default", "source-icon-color");
            vertical-align: bottom;
            margin-right: 5px;
        }
        &:hover,
        &:hover .tide-icon {
            color: themes.get-from-theme("default", "source-link-hover");
            fill: themes.get-from-theme("default", "source-link-hover");
            transition: color ease-in .2s;
        }
    }
}

/********************************************************************
 * Flat Themes:
 ********************************************************************/
.tide-style.theme-flat {
    border-radius: themes.get-from-theme("default", "flat-base-border-radius");
    box-shadow: none;
    border: themes.get-from-theme("default", "flat-base-border");

    .tide-card-content>ul {
        margin-top: 0;
    }
    .tide-card-content ul.tide-card-table-header {
        margin-bottom: 5px;
    }
    .tide-card-content>ul li {
        border-bottom: themes.get-from-theme("default", "flat-content-rows-border");
    }
    .tide-card-content>ul li:nth-last-child(-n+3) {
        border-bottom: 0px
    }
    .tide-card-source a {
        border-radius: 0;
        width: 100%;
        max-width: 100%;
        box-shadow: none;
    }
}
/********************************************************************
 * Apply Themes:
 ********************************************************************/


 @each $theme, $values in themes.$themes {
    @if $theme != default {
        @include themes.theme($theme, $values);
    }
 }


